export class HardcodedUser {

  name: string;
  auth: string;

  constructor(name: string, auth: string) {
    this.name = name;
    this.auth = auth;
  }
}
