<div class="container mx-auto flex text-gray-600">
  <div class="side-bar min-h-screen w-1/6 hidden md:block flex-none bg-lime-900">
    <div class="h-14 py-1">
      <a [routerLink]="['/']" role="button">
        <img src="/assets/logo.png" class="max-h-full mx-auto" alt="Promis" />
      </a>
    </div>
  </div>
  <div class="grow min-h-screen flex flex-col">
    <div class="top-bar-container flex-none">
      <app-topbar></app-topbar>
    </div>
    <div class="grow w-full bg-gray-200">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>