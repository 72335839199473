import {Component, Inject} from '@angular/core';
import {HardcodedUserService} from "../services/hardcoded-user.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {

  private_nemid = false;
  idle_time_reached = false;

  constructor(private hardcodedUserService: HardcodedUserService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.private_nemid = this.data.private_nemid;
    this.idle_time_reached = this.data.idle_time_reached;
  }

  logout() {
    this.hardcodedUserService.logout();
  }
}
