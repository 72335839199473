
// pdf, doc, docx, xls, xlsx, png, jpg og gif.

const ALLOWED_FILE_TYPES = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "image/png",
  "image/jpeg",
  "image/gif"
];

const VALID_FILENAME_REGEXP = /^[\w+æøåÆØÅ,\s-]+\.[A-Za-z]{3,4}$/;

const isAttachmentValid = (file: File) : boolean  => {
  return ALLOWED_FILE_TYPES.includes(file.type) &&
         VALID_FILENAME_REGEXP.test(file.name);
}

export { isAttachmentValid }
