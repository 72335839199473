import { Component, OnInit } from '@angular/core';

import { Application } from '../model/Application';
import { ApplicationService } from '../services/application.service';
import { ConfigurationPayload } from '../model/api/configuration/ConfigurationPayload';
import { ConfigurationService } from '../services/configuration.service';
import { SortBy } from '../model/SortBy';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Role } from '../model/Role';
import { HardcodedUserService } from '../services/hardcoded-user.service';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss']
})
export class ApplicationListComponent implements OnInit {

  faMagnifyingGlass = faMagnifyingGlass;
  SortBy = SortBy;

  search: string = '';
  searchIncludeSentToAgency: boolean = false;
  searchIncludeRejected: boolean = false;
  searchIncludeReturnedToApplicant: boolean = false;
  currentSortBy?: SortBy;
  orderAsc = true;

  configurationPayload?: ConfigurationPayload;

  allApplications: Application[] = [];
  currentApplications: Application[] = [];

  isApplicationListLoading = false;

  Role = Role;
  currentUserRole: Role = Role.NONE;

  constructor(private hardcodedUserService: HardcodedUserService,
              private configurationService: ConfigurationService,
              private applicationService: ApplicationService) {
  }

  ngOnInit(): void {
    this.isApplicationListLoading = true;

    this.currentUserRole = this.hardcodedUserService.getCurrentUserRole();

    this.configurationService.getConfigurationPayload()
      .subscribe(configurationPayload => {
        this.configurationPayload = configurationPayload;
        this.isApplicationListLoading = false;

        this.reloadApplicationList();
      });
  }

  reloadApplicationList() {
    this.isApplicationListLoading = true;

    this.applicationService
      .getApplications()
      .subscribe(applications => {
        this.allApplications = applications;
        this.currentApplications = this.allApplications;
        this.isApplicationListLoading = false;

        this.searchUpdated(this.search);
      });

  }

  delete(application: Application) {
    if (confirm(`Slet #${application.id}: ${application.projectTitle}?`)) {
      this.isApplicationListLoading = true;

      this.applicationService
        .deleteApplication(application)
        .subscribe(() => {
          this.isApplicationListLoading = false;
          this.reloadApplicationList();
        });
    }
  }

  isEditable(application: Application): boolean {
    return ((this.currentUserRole === Role.APPLICANT) && ((application.status === 'draft') || (application.status === 'returned_to_applicant')))
      || ((this.currentUserRole === Role.COORDINATOR) && ((application.status === 'submitted_to_coordinator') || (application.status === 'returned_to_coordinator')));
  }

  sortBy(sb: SortBy) {
    if (this.currentSortBy == sb) {
      if (!this.orderAsc) {
        this.currentSortBy = undefined;
      }
      this.orderAsc = !this.orderAsc;
    } else {
      this.currentSortBy = sb;
      this.orderAsc = true;
    }

    this.searchUpdated(this.search);
  }

  toDisplayApplicationType(applicationType: string) {
    if (this.configurationPayload) {
      return this.configurationService.parseApplicationType(applicationType, this.configurationPayload);
    }

    return '';
  }

  toDisplayStatus(status: string) {
    if (this.configurationPayload) {
      if (status === 'submitted_to_agency' || status === 'completed')
        return "Indsendt til styrelsen"
      else
        return this.configurationService.parseStatus(status, this.configurationPayload);
    }

    return '';
  }

  searchUpdated(value: string) {
    if (!value) {
      this.search = '';
      this.currentApplications = [...this.allApplications];

    } else {
      value = value.toLowerCase();
      this.search = value;

      this.currentApplications = this.allApplications.filter(application =>
        (application.projectTitle && application.projectTitle.toLowerCase().indexOf(value) > -1)
        || (application.projectCaseId && application.projectCaseId.toLowerCase().indexOf(value) > -1)
        || (application.id && (application.id + '').indexOf(value) > -1)
        || (application.applicationType && this.toDisplayApplicationType(application.applicationType).toLowerCase().indexOf(value) > -1)
        || (application.status && this.toDisplayStatus(application.status).toLowerCase().indexOf(value) > -1)
        || ((this.currentUserRole != Role.APPLICANT) && application.cvr && application.cvr.indexOf(value) > -1)
        || ((this.currentUserRole != Role.APPLICANT) && application.companyName && application.companyName.toLowerCase().indexOf(value) > -1)
      )
    }

    if ((this.currentUserRole != Role.APPLICANT) && !this.searchIncludeSentToAgency) {
      this.currentApplications = this.currentApplications.filter(application =>
        application.status && (application.status != 'submitted_to_agency') && (application.status != 'completed')
      );
    }

    if ((this.currentUserRole != Role.APPLICANT) && !this.searchIncludeRejected) {
      this.currentApplications = this.currentApplications.filter(application =>
        application.status && (application.status != 'rejected')
      );
    }

    if ((this.currentUserRole != Role.APPLICANT) && !this.searchIncludeReturnedToApplicant) {
      this.currentApplications = this.currentApplications.filter(application =>
        application.status && (application.status != 'returned_to_applicant')
      );
    }

    this.sortCurrentApplications()
  }

  searchIncludeSentToAgencyUpdated(value: boolean) {
    this.searchIncludeSentToAgency = value;

    this.searchUpdated(this.search);
  }

  searchIncludeRejectedUpdated(value: boolean) {
    this.searchIncludeRejected = value;

    this.searchUpdated(this.search);
  }

  searchIncludeReturnedToApplicantUpdated(value: boolean) {
    this.searchIncludeReturnedToApplicant = value;

    this.searchUpdated(this.search);
  }

  sortCurrentApplications() {
    if (this.currentApplications && this.currentSortBy) {
      this.currentApplications.sort((a1, a2) => {
        if (this.currentSortBy == SortBy.id) {
          return this.orderAsc
            ? a1.id < a2.id ? -1 : a1.id > a2.id ? 1 : 0
            : a1.id < a2.id ? 1 : a1.id > a2.id ? -1 : 0
        } else if (this.currentSortBy == SortBy.projectTitle) {
          if (!a1.projectTitle) {
            return this.orderAsc ? -1 : 1;
          } else if (!a2.projectTitle) {
            return this.orderAsc ? 1 : -1;
          }

          return this.orderAsc
            ? a1.projectTitle.toLowerCase() < a2.projectTitle.toLowerCase() ? -1 : a1.projectTitle.toLowerCase() > a2.projectTitle.toLowerCase() ? 1 : 0
            : a1.projectTitle.toLowerCase() < a2.projectTitle.toLowerCase() ? 1 : a1.projectTitle.toLowerCase() > a2.projectTitle.toLowerCase() ? -1 : 0
        }
        else if (this.currentSortBy == SortBy.projectCaseId) {
          if (!a1.projectCaseId) {
            return this.orderAsc ? -1 : 1;
          } else if (!a2.projectCaseId) {
            return this.orderAsc ? 1 : -1;
          }

          return this.orderAsc
            ? a1.projectCaseId.toLowerCase() < a2.projectCaseId.toLowerCase() ? -1 : a1.projectCaseId.toLowerCase() > a2.projectCaseId.toLowerCase() ? 1 : 0
            : a1.projectCaseId.toLowerCase() < a2.projectCaseId.toLowerCase() ? 1 : a1.projectCaseId.toLowerCase() > a2.projectCaseId.toLowerCase() ? -1 : 0
        }
        else if (this.currentSortBy == SortBy.applicationType) {
          const at1 = this.toDisplayApplicationType(a1.applicationType).toLowerCase();
          const at2 = this.toDisplayApplicationType(a2.applicationType).toLowerCase();

          return this.orderAsc
            ? at1 < at2 ? -1 : at1 > at2 ? 1 : 0
            : at1 < at2 ? 1 : at1 > at2 ? -1 : 0
        } else if (this.currentSortBy == SortBy.status) {
          const s1 = this.toDisplayStatus(a1.status).toLowerCase();
          const s2 = this.toDisplayStatus(a2.status).toLowerCase();

          return this.orderAsc
            ? s1 < s2 ? -1 : s1 > s2 ? 1 : 0
            : s1 < s2 ? 1 : s1 > s2 ? -1 : 0
        } else if (this.currentSortBy == SortBy.cvr) {
          if (!a1.cvr) {
            return this.orderAsc ? -1 : 1;
          } else if (!a2.cvr) {
            return this.orderAsc ? 1 : -1;
          }

          return this.orderAsc
            ? a1.cvr < a2.cvr ? -1 : a1.cvr > a2.cvr ? 1 : 0
            : a1.cvr < a2.cvr ? 1 : a1.cvr > a2.cvr ? -1 : 0
        } else if (this.currentSortBy == SortBy.companyName) {
          if (!a1.companyName) {
            return this.orderAsc ? -1 : 1;
          } else if (!a2.companyName) {
            return this.orderAsc ? 1 : -1;
          }

          return this.orderAsc
            ? a1.companyName.toLowerCase() < a2.companyName.toLowerCase() ? -1 : a1.companyName.toLowerCase() > a2.companyName.toLowerCase() ? 1 : 0
            : a1.companyName.toLowerCase() < a2.companyName.toLowerCase() ? 1 : a1.companyName.toLowerCase() > a2.companyName.toLowerCase() ? -1 : 0
        }

        return 0;
      })
    }
  }

  showFilteringSentToAgency = () => (this.currentUserRole == Role.COORDINATOR) || (this.currentUserRole == Role.BOARD_MEMBER);
  showFilteringRejected = () => (this.currentUserRole == Role.COORDINATOR) || (this.currentUserRole == Role.BOARD_MEMBER);
  showFilteringReturnedToApplicant = () => (this.currentUserRole == Role.COORDINATOR) || (this.currentUserRole == Role.BOARD_MEMBER);
}
