import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { ConfigurationPayload } from '../model/api/configuration/ConfigurationPayload';
import { HardcodedUserService } from './hardcoded-user.service';
import {
  ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue
} from '../model/api/configuration/ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private httpClient: HttpClient,
              private hardcodedUserService: HardcodedUserService) {
  }

  getConfigurationPayload(): Observable<ConfigurationPayload> {
    const currentUser = this.hardcodedUserService.getCurrentUser();
    const token = this.hardcodedUserService.getToken();

    if (!currentUser || !token) {
      return EMPTY;
    }

    const headers = new HttpHeaders({'Authorization': 'Bearer ' + token});
    const options = {headers: headers}

    return this.httpClient.get<ConfigurationPayload>('/reporting/api/latest/reportdefinition', options);
  }

  parseApplicationType(raw: string, configurationPayload: ConfigurationPayload): string {
    const reportFields = configurationPayload.reportDefinition.reportFields.find(reportFields => reportFields.name == 'application_type');
    if (reportFields) {
      const value = reportFields.inputRule.value.find(value => value.name === raw);
      if (value) {
        return value.displayName;
      }
    }

    return '';
  }

  parseStatus(raw: string, configurationPayload: ConfigurationPayload) {
    const reportStatus = configurationPayload.reportDefinition.reportStatus.find(reportStatus => reportStatus.name === raw);
    if (reportStatus) {
      return reportStatus.displayName;
    }

    return '';
  }

  parseDocumentType(raw: string, configurationPayload: ConfigurationPayload) {
    const reportFields = configurationPayload.reportDefinition.reportFields.find(reportFields => reportFields.name == 'appendix_list.document_type');
    if (reportFields) {
      const value = reportFields.inputRule.value.find(value => value.name === raw);
      if (value) {
        return value.displayName;
      }
    }

    return '';
  }

  parseCoordinatorDocumentType(raw: string, configurationPayload: ConfigurationPayload) {
    const reportFields = configurationPayload.reportDefinition.reportFields.find(reportFields => reportFields.name == 'coordinator_appendix_list.coordinator_document_type');
    if (reportFields) {
      const value = reportFields.inputRule.value.find(value => value.name === raw);
      if (value) {
        return value.displayName;
      }
    }

    return '';
  }

  getApplicationTypes(configurationPayload: ConfigurationPayload): ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue[] {
    return this.getReportFields(configurationPayload, 'application_type');
  }

  getLagOrganizations(configurationPayload: ConfigurationPayload): ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue[] {
    return this.getReportFields(configurationPayload, 'lag_organization');
  }

  getDocumentTypes(configurationPayload: ConfigurationPayload): ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue[] {
    return this.getReportFields(configurationPayload, 'appendix_list.document_type');
  }

  getCoordinatorDocumentTypes(configurationPayload: ConfigurationPayload): ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue[] {
    return this.getReportFields(configurationPayload, 'coordinator_appendix_list.coordinator_document_type');
  }

  private getReportFields(configurationPayload: ConfigurationPayload, reportFieldName: string): ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue[] {
    const reportFields = configurationPayload.reportDefinition.reportFields.find(reportFields => reportFields.name == reportFieldName);
    if (reportFields) {
      return reportFields.inputRule.value;
    }

    return [];
  }

  getContextHelp(configurationPayload: ConfigurationPayload, reportFieldName: string): string {
    const reportFields = configurationPayload.reportDefinition.reportFields.find(reportFields => reportFields.name == reportFieldName);
    if (reportFields) {
      return reportFields.contextHelp;
    }

    return reportFieldName;
  }
}
