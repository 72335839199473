import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationListComponent } from './application-list/application-list.component';
import { ApplicationEditComponent } from './application-edit/application-edit.component';

const routes: Routes = [
  { path: '', component: ApplicationListComponent },
  { path: 'login', component: ApplicationListComponent },
  { path: 'view/:id', component: ApplicationEditComponent },
  { path: 'edit/:id', component: ApplicationEditComponent },
  { path: 'new', component: ApplicationEditComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
