import { AppendixFile } from './AppendixFile';

export class Application {

  id: number;
  uuid: string;
  lagOrganization: string;
  projectTitle: string;
  projectCaseId: string;
  applicationType: string;
  status: string;
  email: string;
  cvr: string;
  companyName: string;
  applicantName: string;
  applicantUserName: string;
  createdBy: string
  files: AppendixFile[];
  coordinatorFiles: AppendixFile[];

  constructor(id: number, uuid: string, lagOrganization: string, projectTitle: string, projectCaseId: string, applicationType: string, status: string, email: string, cvr: string, companyName: string, applicantName: string, applicantUserName: string, createdBy: string, files: AppendixFile[], coordinatorFiles: AppendixFile[]) {
    this.id = id;
    this.uuid = uuid;
    this.lagOrganization = lagOrganization;
    this.projectTitle = projectTitle;
    this.projectCaseId = projectCaseId;
    this.applicationType = applicationType;
    this.status = status;
    this.email = email;
    this.cvr = cvr;
    this.companyName = companyName;
    this.applicantName = applicantName;
    this.applicantUserName = applicantUserName;
    this.createdBy = createdBy;
    this.files = files;
    this.coordinatorFiles = coordinatorFiles;
  }
}
