import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue
} from '../model/api/configuration/ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue';
import { AppendixFile } from '../model/AppendixFile';

@Component({
  selector: 'attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent {

  @Input() readonly: boolean = false;

  @Input() file: AppendixFile = new AppendixFile('', '', '');
  @Input() documentTypes: ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue[] = [];

  @Output() deleteFile = new EventEmitter<AppendixFile>();
  @Output() downloadFile = new EventEmitter<AppendixFile>();

  constructor() {}

  deleteClicked() {
    if (this.file) {
      this.deleteFile.emit(this.file);
    }
  }

  downloadFileClicked() {
    if (this.file) {
      this.downloadFile.emit(this.file);
    }
  }
}
