export enum SortBy {
  none,
  id,
  projectTitle,
  projectCaseId,
  applicationType,
  status,
  cvr,
  companyName
}
