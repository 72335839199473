import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationListComponent } from './application-list/application-list.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { ApplicationEditComponent } from './application-edit/application-edit.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ArrowComponent } from './application-list/arrow/arrow.component';
import { ButtonComponent } from './ui-toolkit/button/button.component';
import { FormsModule } from '@angular/forms';
import { FormGroupComponent } from './form-group/form-group.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { SpinnerComponent } from './ui-toolkit/spinner/spinner.component';
import {ErrorInterceptor} from './interceptor/error.interceptor';
import { DialogComponent } from './dialog/dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

@NgModule({
  declarations: [
    AppComponent,
    ApplicationListComponent,
    ApplicationEditComponent,
    TopbarComponent,
    ArrowComponent,
    ButtonComponent,
    FormGroupComponent,
    AttachmentComponent,
    SpinnerComponent,
    DialogComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    FontAwesomeModule,
    MatDialogModule,
    MatButtonModule,
    BrowserAnimationsModule
  ],
  providers: [{
    provide:HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
