import { Component, Input } from '@angular/core';
import { SortBy } from '../../model/SortBy';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-arrow',
  templateUrl: './arrow.component.html',
  styleUrls: ['./arrow.component.scss']
})
export class ArrowComponent {

  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;

  @Input('currentSortBy') currentSortBy?: SortBy;
  @Input() wantedSortBy?: SortBy;
  @Input() orderAsc?: boolean;
}
