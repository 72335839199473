<div *ngIf="isApplicationListLoading" class="bg-gray-50 py-4 px-8 text-2xl text-gray-500 border-b border-gray-300">
  Velkommen
</div>

<div *ngIf="!isApplicationListLoading" class="bg-gray-50 py-4 px-8 text-2xl text-gray-500 border-b border-gray-300">
  Tilknyttede filer til mine LAG ansøgninger
</div>

<div class="px-8 pb-14">
  <div *ngIf="isApplicationListLoading && this.currentUserRole === Role.NONE" class="py-4 mt-2">
    Velkommen til Appendix, PLST’s system til håndtering af bilag i forbindelse med ansøgninger til Landbrugsstyrelsen Tast selv.
    Systemet anvendes sammen med Landbrugsstyrelsens Tast selv skema, hvor selve ansøgningen behandles.
    <br/><br/>
    Du logger ind med dit MitID via nedenstående Link.<br/>
    <div class="container">
      <div class="row">
        <div class="col-sm display-login">
          <button class="bg-lime-900 btn btn-primary" style="border-radius: 4px; padding: 5%" type="btn">
            <a href="/access/saml/samllogin?targetUrl=/">
              <p style="color: white">NEMLOG-IN</p>
            </a>
          </button>
        </div>
      </div>
    </div>

    <br/>
    Hvis din rolle er Koordinator/Bestyrelsesmedlem eller Sagsbehandler skal du efter første login kontakte Lagtilskud@plst.dk, som vil tildele dig de nødvendige rettigheder.
  </div>

  <div class="py-4 mt-2" *ngIf="currentUserRole === Role.APPLICANT">
    <div>
      Velkommen til Appendix. Her vedhæfter du relevante filer til projektansøgningen.
      Appendix anvendes også til filer ved en eventuel ændringsanmodning og ved afslutning af projektet, når der skal anmodes om udbetaling.
      Appendix anvendes altid i sammenhæng med Landbrugsstyrelsens Tast selv skema.
      <br/><br/>
      Du kan finde hjælp til at uploade filer på følgende <a style="color: blue" href="https://www.livogland.dk" target="_blank" rel="noopener noreferrer">vejledning</a> eller få hjælp via hjælpeboblerne til højre for hver felt.<br/><br/>
    </div>

    <promis-button [isLarge]="true" [routerLink]="['new']">Tilknyt filer til ny Tast selv ansøgning >>
    </promis-button>
  </div>

  <div class="py-4 mt-2" *ngIf="currentUserRole === Role.COORDINATOR">
    <div>
      Velkommen til Appendix. Her kan du, som koordinator, se de filer, som ansøger har sendt samt uploade dine egne filer til sagen.
      Du bedes gennemgå og sikre at ansøger, har indsendt korrekt og nødvendigt dokumentation. Det er også muligt at oprette en ny sag som koordinator eller på vegne af en ansøger.
      <br/><br/>
      Husk at vedhæftet ansøgningen og evt. indstilling fra tast selv, så bestyrelsen kan se ansøgningen og indstillingen.
      <br/><br/>
      Du kan finde hjælp til vedhæftning af filer på følgende <a style="color: blue" href="https://www.livogland.dk" target="_blank" rel="noopener noreferrer">vejledning</a> eller få hjælp via hjælpeboblerne til højre for hver felt.
      <br/><br/>
      Hvis du optræder som ansøger for din LAG eller ønsker at indsende jeres driftsregnskab, skal dette foregå via mail <a style="color: blue" href="mailto:lagtilskud@plst.dk">lagtilskud@plst.dk</a>.
      <br/><br/>
    </div>

    <promis-button [isLarge]="true" [routerLink]="['new']">Tilknyt filer til ny Tast selv ansøgning >>
    </promis-button>
  </div>

  <div class="py-4 mt-2" *ngIf="currentUserRole === Role.BOARD_MEMBER">
    <div>
      Velkommen til Appendix, her kan du se og læse ansøgninger og indstillingsmateriale.
      <br/><br/>
      Har du brug for hjælp, kontakt LAG’ens koordinator.
    </div>
  </div>

  <div *ngIf="!isApplicationListLoading" class="w-full flex justify-end pt-4 pb-3">
    <div class="w-96 text-sm">
      <div class=" flex justify-end items-center relative ">
        <input placeholder="Indtast søgeord at filtrere listen" class="border border-gray-300 p-2 w-full"
          [ngModel]="search" (ngModelChange)="searchUpdated($event)" />
        <button class="absolute mr-2 w-6" type="button">
          <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
        </button>
      </div>
      <div *ngIf="showFilteringSentToAgency()" class="mt-1 text-left">
        <input type="checkbox" id="includeSentToBoard" name="includeSentToBoard" [ngModel]="searchIncludeSentToAgency" (ngModelChange)="searchIncludeSentToAgencyUpdated($event)" />
        <label for="includeSentToBoard" class="ml-2 select-none">Inkludér ansøgninger med status "Indsendt til Styrelsen"</label>
      </div>
      <div *ngIf="showFilteringRejected()" class="mt-1 text-left">
        <input type="checkbox" id="includeRejected" name="includeRejected" [ngModel]="searchIncludeRejected" (ngModelChange)="searchIncludeRejectedUpdated($event)" />
        <label for="includeRejected" class="ml-2 select-none">Inkludér ansøgninger med status "Afvist"</label>
      </div>
      <div *ngIf="showFilteringReturnedToApplicant()" class="mt-1 text-left">
        <input type="checkbox" id="includeReturnedToApplicant" name="includeRejected" [ngModel]="searchIncludeReturnedToApplicant" (ngModelChange)="searchIncludeReturnedToApplicantUpdated($event)" />
        <label for="includeRejected" class="ml-2 select-none">Inkludér ansøgninger med status "Retur til ansøger"</label>
      </div>
    </div>
  </div>

<!--  <promis-spinner *ngIf="isApplicationListLoading"></promis-spinner>-->

  <table *ngIf="!isApplicationListLoading"
    class="table-auto px-4 w-full max-w-full divide-y divide-gray-300 border border-gray-300 rounded-lg">
    <thead class="text-left divide-y divide-gray-300 bg-white">
      <tr class="divide-x divide-gray-300 [&_th]:px-3 [&_th]:py-2">
        <th scope="col" (click)="sortBy(SortBy.id)" class="sort">
          #
          <app-arrow [currentSortBy]="currentSortBy" [wantedSortBy]="SortBy.id" [orderAsc]="orderAsc"></app-arrow>
        </th>
        <th scope="col" (click)="sortBy(SortBy.projectTitle)" class="sort">
          Projekttitel
          <app-arrow [currentSortBy]="currentSortBy" [wantedSortBy]="SortBy.projectTitle" [orderAsc]="orderAsc">
          </app-arrow>
        </th>
        <th scope="col" (click)="sortBy(SortBy.projectCaseId)" class="sort">
          Journalnummer
          <app-arrow [currentSortBy]="currentSortBy" [wantedSortBy]="SortBy.projectCaseId" [orderAsc]="orderAsc">
          </app-arrow>
        </th>
        <th scope="col" (click)="sortBy(SortBy.applicationType)" class="sort">
          Ansøgningstype
          <app-arrow [currentSortBy]="currentSortBy" [wantedSortBy]="SortBy.applicationType" [orderAsc]="orderAsc">
          </app-arrow>
        </th>
        <th scope="col" (click)="sortBy(SortBy.status)" class="sort">
          Status
          <app-arrow [currentSortBy]="currentSortBy" [wantedSortBy]="SortBy.status" [orderAsc]="orderAsc"></app-arrow>
        </th>
        <th scope="col" (click)="sortBy(SortBy.cvr)" class="sort" *ngIf="currentUserRole != Role.APPLICANT">
          CVR
          <app-arrow [currentSortBy]="currentSortBy" [wantedSortBy]="SortBy.cvr" [orderAsc]="orderAsc"></app-arrow>
        </th>
        <th scope="col" (click)="sortBy(SortBy.companyName)" class="sort" *ngIf="currentUserRole != Role.APPLICANT">
          Projektansøger
          <app-arrow [currentSortBy]="currentSortBy" [wantedSortBy]="SortBy.companyName" [orderAsc]="orderAsc"></app-arrow>
        </th>
        <th scope="col" colspan="3" class="text-center">Handling</th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-300">
      <tr class="divide-x divide-gray-300  [&_td]:px-3 [&_td]:py-2 [&:nth-child(even)]:bg-white"
        *ngFor="let application of currentApplications">
        <td>{{ application.id }}</td>
        <td>{{ application.projectTitle }}</td>
        <td>{{ application.projectCaseId }}</td>
        <td>{{ toDisplayApplicationType(application.applicationType) }}</td>
        <td>{{ toDisplayStatus(application.status) }}</td>
        <td *ngIf="currentUserRole != Role.APPLICANT">{{ application.cvr }}</td>
        <td *ngIf="currentUserRole != Role.APPLICANT">{{ application.companyName }}</td>
        <td class="text-center">
          <a href="#" [routerLink]="['view/', application.id]" class="text-lime-700 hover:text-lime-900"
            role="button">Vis</a>
        </td>
        <td class="text-center" *ngIf="currentUserRole !== Role.BOARD_MEMBER">
          <a *ngIf="isEditable(application)" href="#" [routerLink]="['edit/', application.id]"
            class="text-lime-700 hover:text-lime-900" role="button">Rediger</a>
          <span *ngIf="!isEditable(application)" class="text-gray-300 select-none">Rediger</span>
        </td>
        <td class="text-center" *ngIf="currentUserRole === Role.APPLICANT">
          <a *ngIf="isEditable(application)" href="#" (click)="delete(application)"
            class="text-lime-700 hover:text-lime-900" role="button">Slet</a>
          <span *ngIf="!isEditable(application)" class="text-gray-300 select-none">Slet</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
