<div class="border border-gray-300 px-4 py-2 font-bold bg-gray-50 text-sm text-black flex w-full justify-between">
    <div>{{ displayTitle }}</div>
    <div class="group flex relative">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="w-6 h-6 text-gray-700"
            viewBox="0 0 16 16">
            <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
        </svg>

        <div class="opacity-0 group-hover:opacity-100 transition-opacity bg-gray-100 border border-gray-300 px-2 py-2 text-xs text-black rounded-md absolute left-1/2
        -translate-x-40 translate-y-4 mx-auto w-40 max-w-md cursor-default select-none z-10 pointer-events-none"
           [innerHTML]='tooltip && tooltip.length > 0 ? tooltip : displayTitle'
        ></div>
    </div>
</div>
<div class="px-4 pt-4 pb-6 bg-white border border-gray-300 border-t-0 text-sm">
    <ng-content></ng-content>
</div>
