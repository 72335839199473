import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss']
})
export class FormGroupComponent implements OnInit {

  @Input() displayTitle: string = '';
  @Input() tooltip: string = '';

  constructor() {
  }

  ngOnInit(): void {
  }
}
