import { Component, OnInit } from '@angular/core';
import { HardcodedUserService } from '../services/hardcoded-user.service';
import { HardcodedUser } from '../model/HardcodedUser';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "../dialog/dialog.component";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  loggedIn = false;
  currentUser?: string;
  hardcodedUsers: HardcodedUser[] = []

  constructor(private hardcodedUserService: HardcodedUserService, private route: ActivatedRoute, private router:Router, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.currentUser = this.hardcodedUserService.getCurrentUser();
    this.hardcodedUsers = this.hardcodedUserService.hardcodedUsers;

    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          let params = this.route.snapshot.queryParams;
          if (params["token"] != undefined && params["user"] != undefined ) {
            if (params["token"] === 'invalid' && params["user"] === 'PRIVATE') {
              this.dialog.open(DialogComponent, { disableClose: true, panelClass: 'custom-dialog-container', data: { private_nemid: true} });
            } else {
              this.hardcodedUserService.autoLogin(params["token"]);
              // @ts-ignore
              window.location = window.location.pathname;
            }
          }
        }
      });
  }

  logIn(hardcodedUser: HardcodedUser) {
    this.hardcodedUserService.login(hardcodedUser).subscribe(_ => {
      location.reload();
    });
  }

  logout() {
    this.hardcodedUserService.logout(true);
  }

  isLocalhostOrTest():boolean {
    return window.location.hostname.includes("-test") ||window.location.hostname.includes("cflow") || window.location.hostname.includes("localhost");
  }
}
