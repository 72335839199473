export class AppendixFile {

  id: string;
  name: string;
  documentType: string;

  constructor(id: string, name: string, documentType: string) {
    this.id = id;
    this.name = name;
    this.documentType = documentType;
  }
}
