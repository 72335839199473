<mat-dialog-content *ngIf="private_nemid">
  <h2 matDialogTitle>Du har forsøgt at logge ind med et privat MitID/NemID</h2>
  <p>Venligst login med MitID/NemID Erhverv</p>
</mat-dialog-content>

<mat-dialog-content *ngIf="idle_time_reached">
  <h2 matDialogTitle>Du har været inaktiv i mere end 30 minutter</h2>
  <p>Du vil blive logget ud nu</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button class="bg-lime-600" matDialogClose="true" (click)="logout()">OK</button>
</mat-dialog-actions>
