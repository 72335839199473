import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { isBlank, isEmailValid } from '../utils/string';

import { AppendixFile } from '../model/AppendixFile';
import { Application } from '../model/Application';
import { ApplicationService } from '../services/application.service';
import { ConfigurationPayload } from '../model/api/configuration/ConfigurationPayload';
import {
  ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue
} from '../model/api/configuration/ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue';
import { ConfigurationService } from '../services/configuration.service';
import { HardcodedUserService } from '../services/hardcoded-user.service';
import { Role } from '../model/Role';
import { isAttachmentValid } from "../utils/attachment";
import * as _ from "lodash";
import { User } from '../model/api/user/User';

@Component({
  selector: 'app-application-edit',
  templateUrl: './application-edit.component.html',
  styleUrls: ['./application-edit.component.scss']
})
export class ApplicationEditComponent implements OnInit {

  edit = true;
  isCoordinatorApplication: boolean = false; //Keeps track of if application is created by coordinator for an applicant, or for coordinator himself
  coordinator: User = new User();

  configurationPayload?: ConfigurationPayload = undefined;
  applicationTypes: ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue[] = [];
  lagOrganizations: ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue[] = [];
  documentTypes: ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue[] = [];
  coordinatorDocumentTypes: ConfigurationPayloadReportDefinitionReportFieldsInputRuleValue[] = [];

  application = new Application(0, '', '', '', '', '', 'draft', '', '', '', '', '', '', [], []);
  applications: Application[] = []

  sendable = false;

  applicants: User[] = [];
  chosenApplicant: User = new User();
  emptyUser: User = new User(); //Used when there are no options in the dropdown
  loading_applicants = false;
  private cvrSubject = new Subject<string>();

  isCVRValid = true;
  isApplicantValid = true;
  userExists = true;
  isApplicationTypeValid = true;
  isTitleValid = true;
  isCaseIdValid = true;
  isEmailValid = true;
  isLagOrganizationValid = true;
  applicationWithSimilarTypeAndCaseIdExists = false;

  @ViewChild('fileSelect')
  fileSelectEl?: ElementRef;
  filesLoading = false;

  @ViewChild('coordinatorFileSelect')
  coordinatorFileSelectEl?: ElementRef;
  coordinatorFilesLoading = false;

  previousCommitmentApplications: Application[] = [];
  selectedPreviousCommitmentApplication = new Application(0, '', '', '', '', '', '', '', '', '', '', '', '', [], []);

  Role = Role;
  currentUserRole: Role = Role.NONE;

  attemptToUploadInvalidAttachment: boolean = false;
  attemptToUploadInvalidCoordinatorAttachment: boolean = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private hardcodedUserService: HardcodedUserService,
              private configurationService: ConfigurationService,
              private applicationService: ApplicationService) {
              }

  async ngOnInit(): Promise<void> {
    const view = this.router.url.startsWith('/view');
    this.edit = this.router.url.startsWith('/edit') || this.router.url.startsWith('/new');

    this.currentUserRole = this.hardcodedUserService.getCurrentUserRole();

    if (this.edit && (this.currentUserRole === Role.APPLICANT)) {
      this.hardcodedUserService.getCurrentUserWithInformation().subscribe(user => {
        this.application.cvr = user.cvr;
        this.application.companyName = user.companyName;
        this.application.applicantName = user.personName;
        this.application.applicantUserName = user.username;
      });
    }

    if (this.edit && (this.currentUserRole === Role.COORDINATOR) && this.router.url.startsWith('/new')) {
      this.application.lagOrganization = this.hardcodedUserService.getCurrentUserCvr();
      this.hardcodedUserService.getCurrentUserWithInformation().subscribe(user => {
        this.coordinator = user;
      });
      this.setupCVRSubscription();
    }

    this.route.params.subscribe(async params => {
      const configurationPayloadObservable = this.configurationService.getConfigurationPayload();
      const applicationsObservable = this.applicationService.getApplications();
      const currentUserObservable = this.hardcodedUserService.getCurrentUserWithInformation();

      forkJoin([configurationPayloadObservable, applicationsObservable, currentUserObservable]).subscribe(async results => {
        const configurationPayload = results[0];
        const applications = results[1];
        const currentUser = results[2];
        this.applications = applications;

        this.configurationPayload = configurationPayload;
        this.applicationTypes = this.configurationService.getApplicationTypes(this.configurationPayload);
        this.lagOrganizations = this.configurationService.getLagOrganizations(this.configurationPayload);
        this.documentTypes = this.configurationService.getDocumentTypes(this.configurationPayload);
        this.coordinatorDocumentTypes = this.configurationService.getCoordinatorDocumentTypes(this.configurationPayload);

        // Below is for existing report
        if ((view || this.edit) && !!params['id']) {
          const applicationById = applications.filter(application => application.id == params['id']);
          if (applicationById && applicationById.length) {
            this.application = applicationById[0];
            this.sortApplicationFilesByDocumentTypeDisplayName();
            this.sortApplicationCoordinatorFilesByDocumentTypeDisplayName();
            
            const applicantRole = await this.hardcodedUserService.getRoleByUsernameAsync(this.application.applicantUserName);

            if (applicantRole === Role.COORDINATOR){
              this.isCoordinatorApplication = true;
              this.coordinator = await this.hardcodedUserService.getUserByUsernameAsync(this.application.applicantUserName);
            }

            if (this.currentUserRole === Role.APPLICANT) {
              this.sendable = (this.application.status === 'draft') || (this.application.status === 'returned_to_applicant');
            } else if (this.currentUserRole === Role.COORDINATOR) {
              this.sendable = (this.application.status === 'submitted_to_coordinator') || (this.application.status === 'returned_to_coordinator');
            }
          }
        }

        this.previousCommitmentApplications = applications.filter(application => application.applicationType === 'commitment' && (application.status === 'submitted_to_coordinator' || application.status === 'submitted_to_agency' || application.status === 'completed'));
        if (this.previousCommitmentApplications.length) {
          this.selectedPreviousCommitmentApplication = this.previousCommitmentApplications[0];
        }
      })
    });
  }

  setupCVRSubscription() {
    this.cvrSubject.pipe(
      debounceTime(500),
      switchMap(value => {
        if (value) {
          this.loading_applicants = true;
          return this.fetchData(value.trim());
        } else {
          return of([]);
        }
      })
    ).subscribe(users => {
      if (Array.isArray(users)) {
        this.applicants = users;
        // Setting the first option as the selected value if there are options
        if (this.applicants.length > 0) {
          this.chosenApplicant = this.applicants[0];
        } else {
          this.chosenApplicant = new User(); // No options available
        }
      } else {
        this.applicants = []; // Default to an empty array
        this.chosenApplicant = new User(); // No options available
      }
      this.loading_applicants = false;
    });
  }

  onCVRChange(value: string) {
    this.cvrSubject.next(value);
  }

  fetchData(cvr: string): Promise<User[]> {
    return new Promise((resolve, reject) => {
      this.hardcodedUserService.getUsersByCVR(cvr).subscribe({
        next: users => {
          resolve(users);
        },
        error: err => {
          reject(err);
        }
      });
    });
  }

  cancel() {
    this.router.navigate(['']);
  }

  cancelWithConfirmation() {
    if (confirm('Er du sikker på, at du vil annullere?')) {
      this.router.navigate(['']);
    }
  }

  saveDraft(): void {
    if (this.validate()) {
      this.applicationService
        .saveApplication(this.application)
        .subscribe(_ => this.router.navigate(['']));
    }
  }

  saveCoordinatorFilesToApplication() {
    this.applicationService
      .saveApplication(this.application)
      .subscribe(_ => this.router.navigate(['']));
  }

  
  private setApplicationValues(){
    if (this.isCoordinatorApplication){
      this.application.applicantName = this.coordinator.personName;
      this.application.applicantUserName = this.coordinator.username;
      this.application.cvr = this.coordinator.cvr;
      this.application.companyName = this.lagOrganizations.find(lag => lag.name === this.coordinator.cvr)?.displayName || "";
    }else{
      this.application.applicantName = this.chosenApplicant.personName;
      this.application.applicantUserName = this.chosenApplicant.username;
      this.application.companyName = this.chosenApplicant.companyName;
      this.application.cvr = this.chosenApplicant.cvr;
    }
  }

  async saveNewApplicationAsCoordinator(){
    if (confirm('Er du sikker på, at du vil oprette ansøgning?')){
      const normalFieldsValid = this.validate();

      var coordinatorFieldsValid = true;

      if (!this.isCoordinatorApplication){
        coordinatorFieldsValid = this.validateCVRAndApplicant();
      }
       
      if (coordinatorFieldsValid && normalFieldsValid){
        this.setApplicationValues();
        this.applicationService
          .saveApplication(this.application)
          .subscribe(response => {
            const uuid = response['content']['objectRef'];
            this.application.uuid = uuid;
            this.sendApplication();
          });
      }
    }
  }

  async returnNewApplicationAsCoordinator(){
    if (confirm('Er du sikker på, at du vil oprette og sende applikationen retur til ansøger?')){
      const normalFieldsValid = this.validate();

      var coordinatorFieldsValid = true;
      if (!this.isCoordinatorApplication){
        coordinatorFieldsValid = this.validateCVRAndApplicant();
      }

      if (coordinatorFieldsValid && normalFieldsValid){
        this.setApplicationValues();
        this.applicationService
          .saveApplication(this.application)
          .subscribe(response => {
            const uuid = response['content']['objectRef'];
            this.application.uuid = uuid;
            this.sendApplicationBackToApplicant();
          });
      }
    }
  }

  async saveAndSendApplicationToAgencyAsCoordinator() {
    if (confirm('Er du sikker på, at du vil oprette og sende applikationen til Styrelsen?')) {
      const normalFieldsValid = this.validate();

      var coordinatorFieldsValid = true;
      if (!this.isCoordinatorApplication){
        coordinatorFieldsValid = this.validateCVRAndApplicant();
      }

      if (coordinatorFieldsValid && normalFieldsValid){
        this.setApplicationValues();
        this.applicationService
          .saveApplication(this.application)
          .subscribe(response => {
            const uuid = response['content']['objectRef'];
            this.application.uuid = uuid;
            this.sendApplicationToAgency();
          });
      }
    }
  }

  async rejectNewApplicationAsCoordinator() {
    if (confirm('Er du sikker på, at du vil oprette og afvise denne ansøgning?')) {
      const normalFieldsValid = this.validate();

      var coordinatorFieldsValid = true;
      if (!this.isCoordinatorApplication){
        coordinatorFieldsValid = this.validateCVRAndApplicant();
      }

      if (coordinatorFieldsValid && normalFieldsValid){
        this.setApplicationValues();
        this.applicationService
          .saveApplication(this.application)
          .subscribe(response => {
            const uuid = response['content']['objectRef'];
            this.application.uuid = uuid;
            this.applicationService
              .rejectApplication(this.application)
              .subscribe(_ => this.router.navigate(['']));
          });
      }
    }
  }

  saveAndSendApplication(): void {
    if (!this.edit || this.validate()) {
      this.applicationService
        .saveApplication(this.application)
        .subscribe(response => {
          const uuid = response['content']['objectRef'];
          this.application.uuid = uuid;

          this.sendApplication();
        });
    }
  }

  saveAndSendApplicationBackToApplicantWithConfirmation() {
    if (confirm('Er du sikker på, at du vil sende applikationen tilbage til ansøger?')) {
      this.applicationService
        .saveApplication(this.application)
        .subscribe(_ => this.sendApplicationBackToApplicant());
    }
  }

  saveAndSendApplicationToAgencyWithConfirmation() {
    if (confirm('Er du sikker på, at du vil sende applikationen til Styrelsen?')) {
      this.applicationService
        .saveApplication(this.application)
        .subscribe(_ => this.sendApplicationToAgency());
    }
  }

  validateCVRAndApplicant() {
    this.isCVRValid = !isBlank(this.application.cvr);
    this.isApplicantValid = !isBlank(this.chosenApplicant.personName);
    return this.isCVRValid && this.isApplicantValid
  }

  validate(): boolean {
    this.isApplicationTypeValid = !isBlank(this.application.applicationType);
    this.isTitleValid = !isBlank(this.application.projectTitle); // TODO max length?
    this.isCaseIdValid = !isBlank(this.application.projectCaseId); // TODO max length?
    this.isEmailValid = isEmailValid(this.application.email);
    this.isLagOrganizationValid = !isBlank(this.application.lagOrganization);
      
    this.applicationWithSimilarTypeAndCaseIdExists = this.checkApplicationWithSimilarTypeAndCaseIdExists(
      this.application.applicationType, 
      this.application.projectCaseId,
      this.application.id);

    return this.isApplicationTypeValid
      && this.isTitleValid
      && this.isCaseIdValid
      && this.isEmailValid
      && this.isLagOrganizationValid
      && !this.applicationWithSimilarTypeAndCaseIdExists;
  }


  //This function checks if there already exists an application with similar applicationType and caseId
  checkApplicationWithSimilarTypeAndCaseIdExists(applicationType: string, projectCaseId: string, applicationId: number): boolean {
    const similarApplication = this.applications.find((application) => 
    application.applicationType == applicationType && application.projectCaseId == projectCaseId && application.id != applicationId);

    

    return similarApplication !== undefined
  }

  sendApplication() {
    this.applicationService
      .sendApplication(this.application)
      .subscribe(_ => this.router.navigate(['']));
  }

  sendApplicationBackToApplicantWithConfirmation() {
    if (confirm('Er du sikker på, at du vil sende applikationen tilbage til ansøger?')) {
      this.applicationService
        .sendApplicationBackToApplicant(this.application)
        .subscribe(_ => this.router.navigate(['']));
    }
  }

  sendApplicationToAgencyWithConfirmation() {
    if (confirm('Er du sikker på, at du vil sende applikationen til Styrelsen?')) {
      this.applicationService
        .sendApplicationToAgency(this.application)
        .subscribe(_ => this.router.navigate(['']));
    }
  }

  reject() {
    if (confirm(`Afvis #${this.application.id}: ${this.application.projectTitle}?`)) {
      this.applicationService
        .rejectApplication(this.application)
        .subscribe(_ => this.router.navigate(['']));
    }
  }

  sendApplicationBackToApplicant() {
    this.applicationService
      .sendApplicationBackToApplicant(this.application)
      .subscribe(_ => this.router.navigate(['']));
  }

  sendApplicationToAgency() {
    this.applicationService
      .sendApplicationToAgency(this.application)
      .subscribe(_ => this.router.navigate(['']));
  }

  fileSelected(event: Event) {

    this.attemptToUploadInvalidAttachment = false;

    if (event && event.target) {
      const target = event.target as HTMLInputElement
      if (target.files && target.files.length) {
        let filesObservables: Observable<any>[] = [];

        const files = this.filterValidFiles(target.files);
        this.attemptToUploadInvalidAttachment = target.files.length > files.length;

        if (files.length == 0) {
          return;
        }

        for (let i = 0; i < files.length; i++) {
          filesObservables.push(this.applicationService.uploadFile(files[i]));
        }

        this.filesLoading = true;
        forkJoin(filesObservables).subscribe(uploadedFiles => {
          uploadedFiles.forEach(uploadedFile => this.application.files.push(new AppendixFile(uploadedFile[1], uploadedFile[0], '')));

          if (this.fileSelectEl) {
            this.fileSelectEl.nativeElement.value = '';
          }

          this.filesLoading = false;
        });
      }
    } else {
      if (this.fileSelectEl) {
        this.fileSelectEl.nativeElement.value = '';
      }
    }
  }

  deleteFile(file: AppendixFile) {
    const index = this.application.files.indexOf(file, 0);
    if (index > -1) {
      this.application.files.splice(index, 1);
    }
  }

  coordinatorFileSelected(event: Event) {
    this.attemptToUploadInvalidCoordinatorAttachment = false;
    if (event && event.target) {
      const target = event.target as HTMLInputElement
      if (target.files && target.files.length) {
        let coordinatorFilesObservables: Observable<any>[] = [];

        const files = this.filterValidFiles(target.files);
        this.attemptToUploadInvalidCoordinatorAttachment = target.files.length > files.length;

        if (files.length == 0) {
          return;
        }
        for (let i = 0; i < target.files.length; i++) {
          coordinatorFilesObservables.push(this.applicationService.uploadFile(target.files.item(i)));
        }

        this.coordinatorFilesLoading = true;
        forkJoin(coordinatorFilesObservables).subscribe(uploadedFiles => {
          uploadedFiles.forEach(uploadedFile => this.application.coordinatorFiles.push(new AppendixFile(uploadedFile[1], uploadedFile[0], '')));

          if (this.coordinatorFileSelectEl) {
            this.coordinatorFileSelectEl.nativeElement.value = '';
          }

          this.coordinatorFilesLoading = false;
        });
      }
    } else {
      if (this.coordinatorFileSelectEl) {
        this.coordinatorFileSelectEl.nativeElement.value = '';
      }
    }
  }

  deleteCoordinatorFile(coordinatorFile: AppendixFile) {
    const index = this.application.coordinatorFiles.indexOf(coordinatorFile, 0);
    if (index > -1) {
      this.application.coordinatorFiles.splice(index, 1);
    }
  }

  sortFiles(coordinatorFiles: AppendixFile[]) : AppendixFile[]{
    return _.orderBy(coordinatorFiles,  (f) => this.configurationService.parseDocumentType(f.documentType, this.configurationPayload!), "asc");
  }

  downloadFile(file: AppendixFile) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', `/reporting/api/latest/attachments/stream/${file.id}`);
    link.setAttribute('download', file.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  showFillValuesDropdown =
    () =>
      this.edit
      && this.currentUserRole === Role.APPLICANT
      && this.application.applicationType
      && this.application.applicationType != 'commitment';

  toLagOrganizationDisplayName(lagOrganizationName: string) {
    const lagOrganizationsByName = this.lagOrganizations.filter(lagOrganization => lagOrganization.name === lagOrganizationName);

    if (lagOrganizationsByName.length) {
      return lagOrganizationsByName[0].displayName;
    }

    return '';
  }

  selectPreviousCommitmentApplication() {
    if (this.selectedPreviousCommitmentApplication) {
      this.application.lagOrganization = this.selectedPreviousCommitmentApplication.lagOrganization;
      this.application.projectTitle = this.selectedPreviousCommitmentApplication.projectTitle;
      this.application.projectCaseId = this.selectedPreviousCommitmentApplication.projectCaseId;
      this.application.email = this.selectedPreviousCommitmentApplication.email;
    }
  }

  getContextHelp(reportFieldName: string, title?: string) {

    if (reportFieldName == 'appendix_list' || reportFieldName == 'coordinator_appendix_list') {
      return `
          <div class='flex flex-col space-y-3'>
            <div>Tilladte filtyper:</div>
            <div>
              <ul class="list-disc ml-8">
                <li>pdf, doc, docx, xls, xlsx, png, jpg og gif</li>
              </ul>
            </div>
            <div>Tilladte tegn i filnavne:</div>
            <ul class="list-disc ml-8">
              <li>Alle store og små danske bogstaver</li>
              <li>Alle tal, Mellemrum</li>
              <li>Bindestreg ”-”</li>
              <li>Underscore ”_”.</li>
            </ul>
          </div>
        `;
    }

    if (this.configurationPayload) {
      const contextHelp = this.configurationService.getContextHelp(this.configurationPayload, reportFieldName)
      return contextHelp ? contextHelp : reportFieldName;
    }

    return title ? title : '';
  }

  private sortApplicationFilesByDocumentTypeDisplayName() {
    if (this.configurationPayload && this.currentUserRole !== Role.APPLICANT) {
      this.application.files.sort((file1, file2) => {
        const n1 = file1.documentType; // Now sorted by number prefix, previously sorted alphabetally:  this.configurationService.parseDocumentType(file1.documentType, this.configurationPayload!);
        const n2 = file2.documentType; // Now sorted by number prefix, previously sorted alphabetally:  this.configurationService.parseDocumentType(file2.documentType, this.configurationPayload!);

        if (!n1) {
          return -1;
        } else if (!n2) {
          return 1;
        }

        return n1.toLowerCase() < n2.toLowerCase() ? -1 : n1.toLowerCase() > n2.toLowerCase() ? 1 : 0;
      });
    }
  }

  private sortApplicationCoordinatorFilesByDocumentTypeDisplayName() {
    if (this.configurationPayload) {
      this.application.coordinatorFiles.sort((coordinatorFile1, coordinatorFile2) => {
        const n1 = coordinatorFile1.documentType; // Now sorted by number prefix, previously sorted alphabetally:  this.configurationService.parseCoordinatorDocumentType(, this.configurationPayload!);
        const n2 = coordinatorFile2.documentType; // Now sorted by number prefix, previously sorted alphabetally:  this.configurationService.parseCoordinatorDocumentType(coordinatorFile2.documentType, this.configurationPayload!);

        if (!n1) {
          return -1;
        } else if (!n2) {
          return 1;
        }

        return n1.toLowerCase() < n2.toLowerCase() ? -1 : n1.toLowerCase() > n2.toLowerCase() ? 1 : 0;
      });
    }
  }

  private filterValidFiles(files: FileList): File[] {

    const validFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      file != null && isAttachmentValid(file) && validFiles.push(file);
    }

    return validFiles;
  }

}
