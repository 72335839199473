export class IdleTimer {
  private timeout: number;
  private onTimeout: () => void;
  private interval: NodeJS.Timeout | null;
  private timeoutTracker: NodeJS.Timeout | null;
  private eventHandler: () => void;

  constructor( timeout: number, onTimeout: () => void ) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.interval = null;
    this.timeoutTracker = null;

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
    this.syncTimerState();
  }

  private startInterval(): void {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem("_expiredTime")!, 10);
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  }

  private updateExpiredTime(): void {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem('_expiredTime', String(Date.now() + this.timeout * 1000));
    }, 1000);
  }

  private tracker(): void {
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }

  private cleanUp(): void {
    localStorage.removeItem('_expiredTime');
    if (this.interval) {
      clearInterval(this.interval);
    }
    window.removeEventListener("mousemove", this.eventHandler);
    window.removeEventListener("scroll", this.eventHandler);
    window.removeEventListener("keydown", this.eventHandler);
  }

  private syncTimerState(): void {
    // Listen for changes in the localStorage from other tabs
    window.addEventListener("storage", (event) => {
      if (event.key === "_expiredTime") {
        const expiredTime = parseInt(event.newValue!, 10);
        if (expiredTime < Date.now()) {
          if (this.onTimeout) {
            this.onTimeout();
            this.cleanUp();
          }
        }
      }
    });
  }
}
