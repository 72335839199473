<div class="attachment flex justify-center items-center py-4 border-t border-gray-300">
    <div class="basis-1/3 text-center text-lime-700">
        <a href="javascript:void(0)" role="button" (click)="downloadFileClicked()">
            {{ file.name }}
        </a>
    </div>
    <div class="basis-1/3">
        <select class="bg-white px-3 py-2 w-full border border-gray-300" [disabled]="readonly" name="documentType" [(ngModel)]="file.documentType">
            <option *ngFor="let documentType of documentTypes" value="{{ documentType.name }}">{{ documentType.displayName }}</option>
        </select>
      <div *ngIf="!file.documentType" class="py-2 text-red-500 mb-1">
        Angiv venligst en bilag type.
      </div>
    </div>
    <div class="basis-1/3 text-center">
        <button class="px-3 py-2 bg-red-700 text-white inline-flex items-center" *ngIf="!readonly" (click)="deleteClicked()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="w-4 h-4 mr-2"
                viewBox="0 0 16 16">
                <path
                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
            </svg>
            <span>Slet</span>
        </button>
    </div>
</div>
