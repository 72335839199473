import {Component, OnInit} from '@angular/core';
import {IdleTimer} from "./IdleTimer";
import {HardcodedUserService} from "./services/hardcoded-user.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "./dialog/dialog.component";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private timer: IdleTimer | undefined;

  constructor(private hardcodedUserService: HardcodedUserService, private dialog: MatDialog) {
  }

  ngOnInit() {
    if (this.hardcodedUserService.getCurrentUser()) {

      this.timer = new IdleTimer(1800, () => {
        localStorage.clear();
        this.dialog.open(DialogComponent, { disableClose: true, panelClass: 'custom-dialog-container', data: {idle_time_reached: true} });
      });
    }
  }
}
